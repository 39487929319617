<template>
    <!-- 选择大屏模板弹窗 -->
    <el-dialog width="63%" :title="$t('screen.selecteTemplate')" :visible.sync="templateShow" top="12vh" 
        :before-close="closeSeletedScreen" class="common-map-dialog" :close-on-click-modal="false" append-to-body>
        <div class="screen-template-box">
            <div class="screen-template-box-item" v-for="item in templatesList" :key="item.id">
                <div :class="item.id === chooseTemplateId ? 'bdc-blue' : ''" class="screen-template-img-box">
                    <el-image z-index="9999" style="width: 100%; height: 100%" :src="item.thumbnail" :fit="`fill`" 
                        :preview-src-list="templatesList.map(item => item.describePicture)">
                    </el-image>
                </div>
                <div class="screen-template-radio">
                    <el-radio v-model="chooseTemplateId" :label="item.id">{{item.name}}</el-radio>
                </div>
            </div>
        </div>
        <div slot="footer">
            <el-button size="small" @click="closeSeletedScreen">{{$t('cancelText')}}</el-button>
            <el-button type="primary" size="small" @click="saveSeletedScreen" >{{$t('submitText')}}</el-button>
        </div>
    </el-dialog>
</template>

<script>
import {getScreenTemplateList} from "@/api/project/index";
import currencyScreen from "@/assets/img/srceen/currency_screen.png";
import forestScreen from "@/assets/img/srceen/forest_screen.png";
import petroleumScreen from "@/assets/img/srceen/petroleum_screen.png";
import emergencyScreen from "@/assets/img/srceen/emergency_screen.png";
const screenTypesList = [
    {type: 1, route: 'currency', imgUrl: currencyScreen},
    {type: 2, route: 'forest', imgUrl: forestScreen},
    {type: 3, route: 'petroleum', imgUrl: petroleumScreen},
    {type: 4, route: 'emergency', imgUrl: emergencyScreen}
];

export default {
    name: 'screenTemplate',
    props: {
        // 是否显示
        show: {
            type: Boolean,
            default: false
        },
        // 是否过滤
        isFilterate: {
            type: Boolean,
            default: false
        },
        // 当前选择的大屏模板信息
        screenVOData: {
            type: Object,
            default: function() {
                return {}
            }
        }
    },
    data() {
        return {
            templateShow: false,// 选择模板对话框
            chooseTemplateId: '', // 选择的模板id
            templatesList: [], // 后台模板数据
        }
    },
    watch: {
        show: {
            deep: true,
            handler: function(val) {
                val && this.setData();
            }
        }
    },
    created() {
        this.getScreenTemplateData();
    },
    methods: {
        getScreenTemplateData() {
            getScreenTemplateList({current: 1, size: 1000}).then(res => {
                if (res.data.code === 200) {
                    let templatesList = res.data.data.records || [];
                    let list = [];
                    templatesList.map(t => {
                        let templateItem = screenTypesList.find(i => i.type === t.type && i.route === t.route);
                        if (templateItem) {
                            if (!this.isFilterate) {
                                t.describePicture = templateItem.imgUrl;
                                t.thumbnail = templateItem.imgUrl;
                                list.push(t);
                            } else {
                                if (templateItem.route !== 'forest' && templateItem.route !== 'petroleum') {
                                    t.describePicture = templateItem.imgUrl;
                                    t.thumbnail = templateItem.imgUrl;
                                    list.push(t);
                                }
                            }
                        }
                    });
                    this.templatesList = list;
                }
            });
        },
        // 数据变更后初始化设置
        setData() {
            this.chooseTemplateId = this.screenVOData ? this.screenVOData.id : '';
            this.templateShow = this.show;
        },
        // 关闭大屏模板选择
        closeSeletedScreen() {
            this.templateShow = false;
            this.$emit("update:show", false);
            this.chooseTemplateId = '';
        },
        // 保存大屏模板选择
        saveSeletedScreen() {
            if (!this.chooseTemplateId) {
                return this.$message.warning('请选择要展示的大屏模板!');
            }
            let templateItem = this.templatesList.find(t => t.id === this.chooseTemplateId);
            if (templateItem) {
                let data = {
                    id: templateItem.id,
                    name: templateItem.name,
                    route: templateItem.route
                };
                this.$emit('changeTemplate', data);
            }
            this.closeSeletedScreen();
        }
    }
}
</script>

<style lang="scss">
.screen-template-box {
    max-height: 500px;
    overflow: auto;
    .screen-template-box-item {
        display: inline-block;
        width: calc(33% - 20px);
        height: 250px;
        margin-left: 20px;
        &:nth-child(3n - 2) {
            margin-left: 10px;
        }
        .screen-template-img-box {
            box-sizing: border-box;
            margin: 0 auto;
            width: 90%;
            height: calc(100% - 50px);
            border: 3px solid transparent;
            &.bdc-blue {
                border: 3px solid #265edd;
            }
        }
        .screen-template-radio {
            display: flex;
            height: 50px;
            justify-content: center;
            align-items: center;
        }
    }
}
</style>