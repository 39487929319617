var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "common-map-dialog",
      attrs: {
        width: "63%",
        title: _vm.$t("screen.selecteTemplate"),
        visible: _vm.templateShow,
        top: "12vh",
        "before-close": _vm.closeSeletedScreen,
        "close-on-click-modal": false,
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.templateShow = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "screen-template-box" },
        _vm._l(_vm.templatesList, function (item) {
          return _c(
            "div",
            { key: item.id, staticClass: "screen-template-box-item" },
            [
              _c(
                "div",
                {
                  staticClass: "screen-template-img-box",
                  class: item.id === _vm.chooseTemplateId ? "bdc-blue" : "",
                },
                [
                  _c("el-image", {
                    staticStyle: { width: "100%", height: "100%" },
                    attrs: {
                      "z-index": "9999",
                      src: item.thumbnail,
                      fit: "fill",
                      "preview-src-list": _vm.templatesList.map(function (
                        item
                      ) {
                        return item.describePicture
                      }),
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "screen-template-radio" },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: item.id },
                      model: {
                        value: _vm.chooseTemplateId,
                        callback: function ($$v) {
                          _vm.chooseTemplateId = $$v
                        },
                        expression: "chooseTemplateId",
                      },
                    },
                    [_vm._v(_vm._s(item.name))]
                  ),
                ],
                1
              ),
            ]
          )
        }),
        0
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.closeSeletedScreen } },
            [_vm._v(_vm._s(_vm.$t("cancelText")))]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.saveSeletedScreen },
            },
            [_vm._v(_vm._s(_vm.$t("submitText")))]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }